import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import logo from "./logo.svg";
import "./App.css";
import Login from "./pages/Login";
import SignIn from "./pages/sign-in/SignIn";
import SignUp from "./pages/sign-up/SignUp";
import Home from "./pages/Home";
import PersistLogin from "./components/auth/PersistLogin";
import RequireAuth from "./components/auth/RequireAuth";
import AuthedHome from "./pages/AuthedHome";

import AppTheme from "../src/theme/shared-theme/AppTheme";
import CssBaseline from "@mui/material/CssBaseline";

import SendVerifyEmail from "./components/auth/SendVerifyEmail";
import CompleteEmailVerify from "./components/auth/CompleteEmailVerify";

function App(props) {
  const dispatch = useDispatch();

  // TODO create a persist login component to run account.get() to see if session exists
  // useEffect(async () => {
  //   // Note: check if logged in: https://appwrite.io/docs/products/auth/quick-start
  //   dispatch(initUser);
  // }, [dispatch]);

  return (
    <>
      <AppTheme {...props}>
        <CssBaseline enableColorScheme />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route element={<PersistLogin />}>
            <Route path="/" element={<Home />} />
            <Route element={<RequireAuth />}>
              <Route path="send-verify-email" element={<SendVerifyEmail />} />
              <Route path="verify-email" element={<CompleteEmailVerify />} />
              <Route path="registered/home" element={<AuthedHome />} />
            </Route>
          </Route>
        </Routes>
      </AppTheme>
    </>
  );
}

export default App;
