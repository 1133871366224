import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  login,
  register,
  selectUser,
  selectUserStatus,
  selectUserError,
  selectIsLoggedIn,
} from "../features/user/userSlice";
import { useNavigate, useLocation } from "react-router-dom";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();
  // const location = useLocation();
  // const from = location.state?.from?.pathname || "/";

  const dispatch = useDispatch();

  const status = useSelector(selectUserStatus);
  const userError = useSelector(selectUserError);

  useEffect(() => {
    setError("");
  }, [email, password]);

  useEffect(() => {
    setError(userError);
  }, [userError]);

  const handleLogin = async () => {
    if (!email || !password) {
      setError("Email and password required.");
      return;
    }
    try {
      const response = await dispatch(login({ email, password })).unwrap();
      console.log("Login : handleLogin: response: ", response);
      navigate("/registered/home/", { replace: true });
    } catch (err) {
      console.log("Login error: ", err);
    }
  };

  const handleRegister = async () => {
    if (!email || !password) {
      setError("Email and password required.");
      return;
    }
    try {
      await dispatch(register({ email, password })).unwrap();
      navigate("/registered/home/", { replace: true });
    } catch (err) {
      console.log("Register error: ", err);
    }
  };

  return (
    <section>
      <h1>Log in or Register</h1>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(event) => {
            setPassword(event.target.value);
          }}
        />
        {error && <p className="error">{error}</p>}
        {status === "loading" && <p className="status">Loading ...</p>}

        <div>
          <button
            className="button"
            type="button"
            disabled={status === "loading"}
            onClick={handleLogin}
          >
            Login
          </button>
          <button
            className="button"
            type="button"
            disabled={status === "loading"}
            onClick={handleRegister}
          >
            Register
          </button>
        </div>
      </form>
    </section>
  );
}

export default Login;
