import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { completeVerification } from "../../features/user/userSlice";

import { useSearchParams, useNavigate } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";

function CompleteEmailVerify() {
  const [status, setStatus] = useState("verifying"); // "verifying", "success", "error"
  const [errorMessage, setErrorMessage] = useState("");

  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const verifyEmail = async () => {
      const userId = searchParams.get("userId");
      const secret = searchParams.get("secret");

      if (!userId || !secret) {
        setStatus("error");
        setErrorMessage("Invalid verification link.");
        return;
      }

      try {
        await dispatch(completeVerification({ userId, secret })).unwrap();
        setStatus("success");
        navigate("/registered/home", { replace: true });
      } catch (err) {
        setStatus("error");
        setErrorMessage(
          err.message || "Failed to verify email. Please try again."
        );
      }
    };

    verifyEmail();
    // Note: component should only mount after successful registration and after user clicks verify email link
  }, []);

  const renderContent = () => {
    switch (status) {
      case "verifying":
        return (
          <Typography variant="body2">
            Verifying your email... Please wait.
          </Typography>
        );
      case "success":
        return (
          <Typography variant="body2">
            Your email has been successfully verified. Redirecting to your
            dashboard...
          </Typography>
        );
      case "error":
        return (
          <>
            <Typography variant="body2" color="error">
              {errorMessage}
            </Typography>
            <Button
              variant="contained"
              onClick={() => navigate("/login", { replace: true })}
              sx={{ mt: 2 }}
            >
              Go to Login
            </Button>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "background.default",
      }}
    >
      <Card
        sx={{
          maxWidth: 400,
          p: 3,
          boxShadow:
            "0px 4px 20px rgba(0, 0, 0, 0.1), 0px 8px 40px rgba(0, 0, 0, 0.05)",
        }}
      >
        <CardContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography variant="h5" gutterBottom>
            Complete Email Verification
          </Typography>
          {renderContent()}
        </CardContent>
      </Card>
    </Box>
  );
}

export default CompleteEmailVerify;
