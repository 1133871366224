import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectIsLoggedIn, initUser } from "../../features/user/userSlice";

function PersistLogin() {
  const [isLoading, setIsLoading] = useState(true);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    let isMounted = true;
    // TODO reduce life of access token
    // common error try to login when session already active due to long life cookie
    const checkSession = async () => {
      try {
        // Note: dispatches account.get() which gets currently logged in user
        const response = await dispatch(initUser()).unwrap();
        console.log("PersistLogin: response: ", response);
      } catch (err) {
        console.log("PersistLogin: err: ", err);
      } finally {
        // Note: isLoading set to false even if initUser fails; thus RequireAuth must check auth and redirect
        isMounted && setIsLoading(false);
      }
    };

    console.log("PersistLogin: isLoggedIn: isLoggedIn ", isLoggedIn);

    isLoggedIn ? setIsLoading(false) : checkSession();

    console.log("PersistLogin: isLoading: ", isLoading);

    // Note: when component unmounts
    return () => (isMounted = false);
  }, []);
  return <>{isLoading ? <p>Loading ...</p> : <Outlet />}</>;
}

export default PersistLogin;
