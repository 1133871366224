import React from "react";
import Navbar from "../components/navigation/Navbar";

function Home() {
  return (
    <>
      <Navbar />
      <p>Public Home Page</p>
    </>
  );
}

export default Home;
