import React from "react";
import Navbar from "../components/navigation/Navbar";

function AuthedHome() {
  return (
    <>
      <Navbar />
      <p>Authed Home Page</p>
    </>
  );
}

export default AuthedHome;
