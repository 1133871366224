import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { initUser, selectIsLoggedIn } from "../../features/user/userSlice";

function RequireAuth() {
  // const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const location = useLocation();
  console.log("RequireAuth: location: ", location);

  return isLoggedIn ? (
    <Outlet />
  ) : (
    // Note: replace the location in navigation history with location they came from
    <Navigate to="/login" state={{ from: location }} replace />
  );
}

export default RequireAuth;
