import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ID } from "appwrite";
import { account } from "../../appwrite";

const initialState = {
  userInfo: null,
  status: "idle", // 'idle' | 'loading' | 'error'
  error: null,
  // TODO add isVerified to state
};

export const login = createAsyncThunk(
  "user/login",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await account.createEmailPasswordSession(
        email,
        password
      );
      console.log("userSlice: login: response: ", response);

      const user = await account.get();

      console.log("userSlice: login: user: ", user);

      return user;
    } catch (error) {
      return rejectWithValue(
        error.message || "An unexpected login error occurred."
      );
    }
  }
);

export const logout = createAsyncThunk(
  "user/logout",
  async (_, { rejectWithValue }) => {
    try {
      await account.deleteSession("current");
      return null;
    } catch (error) {
      return rejectWithValue(
        error.message || "An unexpected logout error occurred."
      );
    }
  }
);

export const register = createAsyncThunk(
  "user/register",
  async ({ email, password, name }, { dispatch, rejectWithValue }) => {
    try {
      await account.create(ID.unique(), email, password, name);
      // TODO  /account/verfication route to start verifying the user email address
      // TODO dispatch(createProfile()) ... create a user profile table
      await dispatch(login({ email, password })).unwrap();
    } catch (error) {
      return rejectWithValue(
        error.message || "An unexpected register error occurred."
      );
    }
  }
);

export const initUser = createAsyncThunk(
  "user/initUser",
  async (_, { rejectWithValue }) => {
    try {
      const response = await account.get();
      return response;
    } catch (error) {
      return rejectWithValue(
        error.message || "An unexpected initialize user error occurred."
      );
    }
  }
);

export const sendVerificationEmail = createAsyncThunk(
  "user/sendVerificationEmail",
  async (redirectUrl, { rejectWithValue }) => {
    try {
      const response = await account.createVerification(redirectUrl);
      console.log("userSlice: sendVerificationEmail: response: ", response);
      return response;
    } catch (error) {
      return rejectWithValue(
        error.message || "Failed to send verification email."
      );
    }
  }
);

export const completeVerification = createAsyncThunk(
  "user/completeVerification",
  async ({ userId, secret }, { rejectWithValue }) => {
    try {
      const response = await account.updateVerification(userId, secret);
      console.log("userSlice: completeVerification: response: ", response);
      return response;
    } catch (error) {
      return rejectWithValue(
        error.message || "Failed to verify email address."
      );
    }
  }
);

// TODO after user registers need to create a separate document which will hold other info
export const createProfile = createAsyncThunk(
  "profile/createProfile",
  async ({ email }, { dispatch, rejectWithValue }) => {
    try {
      // TODO id, email, stravaId, premium, newsletter
    } catch (error) {
      return rejectWithValue(error.message || "Failed to initialize profile.");
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  extraReducers: (builder) => {
    builder

      // LOGIN
      .addCase(login.pending, (state) => {
        state.status = "loading";
        state.error = null;
        // state.userInfo = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        console.log("login.fulfilled: action.payload: ", action.payload);
        state.status = "idle";
        state.userInfo = action.payload;
        state.error = null;
      })
      .addCase(login.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload;
        state.userInfo = null;
      })

      // LOGOUT
      .addCase(logout.fulfilled, (state) => {
        state.status = "idle";
        state.userInfo = null;
        state.error = null;
      })
      .addCase(logout.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload;
      })

      // REGISTER
      .addCase(register.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(register.fulfilled, (state) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(register.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload;
      })

      // INIT
      .addCase(initUser.pending, (state) => {
        state.status = "loading";
        state.error = null;
        // state.userInfo = null;
      })
      .addCase(initUser.fulfilled, (state, action) => {
        state.status = "idle";
        state.userInfo = action.payload;
        state.error = null;
      })
      .addCase(initUser.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload;
        state.userInfo = null;
      })

      // SEND VERIFICATION EMAIL
      .addCase(sendVerificationEmail.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(sendVerificationEmail.fulfilled, (state) => {
        state.status = "idle";
        state.error = null;
        console.log(
          "sendVerificationEmail.fulfilled: Email verification sent."
        );
      })
      .addCase(sendVerificationEmail.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload;
      })

      // COMPLETE VERIFICATION
      .addCase(completeVerification.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(completeVerification.fulfilled, (state) => {
        state.status = "idle";
        state.error = null;
        console.log(
          "completeVerification.fulfilled: Email verification complete."
        );
      })
      .addCase(completeVerification.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload;
      });
  },
});

export const selectIsLoggedIn = (state) =>
  state.user.userInfo !== null && state.user.status === "idle";

export const selectUser = (state) => state.user.userInfo;

export const selectUserStatus = (state) => state.user.status;
export const selectUserError = (state) => state.user.error;

export default userSlice.reducer;
